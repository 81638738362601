.cbaf-datatable {
  width: 100%;
  .auto {
    width: auto !important;
  }
}

.table-responsive {
  background-color: #fff !important;
  padding-bottom: 16px;
  &::-webkit-scrollbar {
    height: 20px;
    background: red;
    border-radius: 22px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0063bd;
    border-radius: 22px;
  }
}

.cbaf-datatable {
  &.hover tbody tr:hover {
    background: $background-50;
  }
  thead {
    th {
      border-bottom: 2px solid $bcp-blue;
      border-top: 2px solid $bcp-blue;
      font-size: 15px;
      color: $bcp-blue;
      font-family: "Flexo-Regular", sans-serif;
      padding-bottom: 20px;
      padding-top: 20px;
      white-space: nowrap;
    }
  }
  &.row-border {
    tbody {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
        border-top: none;
        border-bottom: 1px solid $bcp-blue;
        color: $bcp-black;
        font-size: 15px;
        white-space: nowrap;
        .cbaf-form__form-control {
          width: auto;
        }
      }
    }
  }
}
//datatable input search
.dataTables_wrapper {
  .dataTables_paginate {
    padding-top: 1.25em;
    .paginate_button {
      background: #fff !important;
      color: $bcp-gray !important;
      border: none !important;
      font-size: 16px;
      font-weight: 100;
      &:hover {
        color: $bcp-blue !important;
        font-weight: 600;
      }
      &.first,
      &.previous,
      &.next,
      &.last {
        color: $bcp-blue !important;
        font-weight: 500;
      }
      &.current {
        color: $bcp-blue !important;
        font-weight: 600;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
  .dataTables_filter {
    margin: 0 0 25px 0;
    float: left !important;
    input {
      width: 30vw;
      border-bottom: 1px solid $bcp-blue;
      border-top: none;
      border-left: none;
      border-right: none;
      font-style: italic;
      &:focus {
        outline: none;
      }
    }
  }
  .dataTables_length {
    position: absolute;
    bottom: 0;
    left: 0;
    select {
      border: none;
      border-bottom: 1px solid $bcp-blue;
      color: $bcp-blue;
      font-size: 16px;
      font-weight: 600;
      padding: 5px;
      &:focus {
        outline: none;
      }
    }
    label {
      font-size: 16px;
      color: $bcp-blue;
      font-weight: 500;
    }
  }
}

//table.dataTable.row-border tbody td
