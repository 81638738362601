.bcp-agency-b:before {
  content: "\f101";
}

.bcp-agency-l:before {
  content: "\f102";
}

.bcp-agency-r:before {
  content: "\f103";
}

.bcp-airplane-b:before {
  content: "\f104";
}

.bcp-airplane-l:before {
  content: "\f105";
}

.bcp-airplane-r:before {
  content: "\f106";
}

.bcp-angle-down-circle-b:before {
  content: "\f107";
}

.bcp-angle-down-circle-r:before {
  content: "\f108";
}

.bcp-angle-down-r:before {
  content: "\f109";
}

.bcp-angle-drop-down-r:before {
  content: "\f10a";
}

.bcp-angle-drop-left-r:before {
  content: "\f10b";
}

.bcp-angle-drop-right-r:before {
  content: "\f10c";
}

.bcp-angle-drop-up-down-r:before {
  content: "\f10d";
}

.bcp-angle-drop-up-r:before {
  content: "\f10e";
}

.bcp-angle-left-r:before {
  content: "\f10f";
}

.bcp-angle-right-r:before {
  content: "\f110";
}

.bcp-angle-scroll-down-r:before {
  content: "\f111";
}

.bcp-angle-scroll-up-r:before {
  content: "\f112";
}

.bcp-angle-up-r:before {
  content: "\f113";
}

.bcp-arrow-down-r:before {
  content: "\f114";
}

.bcp-arrow-left-r:before {
  content: "\f115";
}

.bcp-arrow-left-right-r:before {
  content: "\f116";
}

.bcp-arrow-right-r:before {
  content: "\f117";
}

.bcp-arrow-sort-down-r:before {
  content: "\f118";
}

.bcp-arrow-sort-up-r:before {
  content: "\f119";
}

.bcp-arrow-to-bottom-r:before {
  content: "\f11a";
}

.bcp-arrow-up-down-r:before {
  content: "\f11b";
}

.bcp-arrow-up-r:before {
  content: "\f11c";
}

.bcp-atm-b:before {
  content: "\f11d";
}

.bcp-atm-l:before {
  content: "\f11e";
}

.bcp-atm-r:before {
  content: "\f11f";
}

.bcp-atm-screen-b:before {
  content: "\f120";
}

.bcp-atm-screen-l:before {
  content: "\f121";
}

.bcp-atm-screen-r:before {
  content: "\f122";
}

.bcp-backspace-b:before {
  content: "\f123";
}

.bcp-backspace-l:before {
  content: "\f124";
}

.bcp-backspace-r:before {
  content: "\f125";
}

.bcp-badge-percent-b:before {
  content: "\f126";
}

.bcp-badge-percent-l:before {
  content: "\f127";
}

.bcp-badge-percent-r:before {
  content: "\f128";
}

.bcp-ban-r:before {
  content: "\f129";
}

.bcp-bank-b:before {
  content: "\f12a";
}

.bcp-bank-l:before {
  content: "\f12b";
}

.bcp-bank-r:before {
  content: "\f12c";
}

.bcp-bell-b:before {
  content: "\f12d";
}

.bcp-bell-l:before {
  content: "\f12e";
}

.bcp-bell-r:before {
  content: "\f12f";
}

.bcp-bill-b:before {
  content: "\f130";
}

.bcp-bill-l:before {
  content: "\f131";
}

.bcp-bill-r:before {
  content: "\f132";
}

.bcp-book-b:before {
  content: "\f133";
}

.bcp-book-l:before {
  content: "\f134";
}

.bcp-book-r:before {
  content: "\f135";
}

.bcp-box-b:before {
  content: "\f136";
}

.bcp-box-l:before {
  content: "\f137";
}

.bcp-box-r:before {
  content: "\f138";
}

.bcp-broom-b:before {
  content: "\f139";
}

.bcp-broom-l:before {
  content: "\f13a";
}

.bcp-broom-r:before {
  content: "\f13b";
}

.bcp-building-b:before {
  content: "\f13c";
}

.bcp-building-l:before {
  content: "\f13d";
}

.bcp-building-r:before {
  content: "\f13e";
}

.bcp-calendar-b:before {
  content: "\f13f";
}

.bcp-calendar-clock-b:before {
  content: "\f140";
}

.bcp-calendar-clock-l:before {
  content: "\f141";
}

.bcp-calendar-clock-r:before {
  content: "\f142";
}

.bcp-calendar-l:before {
  content: "\f143";
}

.bcp-calendar-r:before {
  content: "\f144";
}

.bcp-camera-b:before {
  content: "\f145";
}

.bcp-camera-l:before {
  content: "\f146";
}

.bcp-camera-r:before {
  content: "\f147";
}

.bcp-car-b:before {
  content: "\f148";
}

.bcp-car-l:before {
  content: "\f149";
}

.bcp-car-r:before {
  content: "\f14a";
}

.bcp-card-approved-b:before {
  content: "\f14b";
}

.bcp-card-approved-l:before {
  content: "\f14c";
}

.bcp-card-approved-r:before {
  content: "\f14d";
}

.bcp-card-change-b:before {
  content: "\f14e";
}

.bcp-card-change-l:before {
  content: "\f14f";
}

.bcp-card-change-r:before {
  content: "\f150";
}

.bcp-card-credit-b:before {
  content: "\f151";
}

.bcp-card-credit-digital-b:before {
  content: "\f152";
}

.bcp-card-credit-digital-l:before {
  content: "\f153";
}

.bcp-card-credit-digital-r:before {
  content: "\f154";
}

.bcp-card-credit-l:before {
  content: "\f155";
}

.bcp-card-credit-r:before {
  content: "\f156";
}

.bcp-card-denied-b:before {
  content: "\f157";
}

.bcp-card-denied-l:before {
  content: "\f158";
}

.bcp-card-denied-r:before {
  content: "\f159";
}

.bcp-card-gear-b:before {
  content: "\f15a";
}

.bcp-card-gear-l:before {
  content: "\f15b";
}

.bcp-card-gear-r:before {
  content: "\f15c";
}

.bcp-card-less-b:before {
  content: "\f15d";
}

.bcp-card-less-l:before {
  content: "\f15e";
}

.bcp-card-less-r:before {
  content: "\f15f";
}

.bcp-card-plus-b:before {
  content: "\f160";
}

.bcp-card-plus-l:before {
  content: "\f161";
}

.bcp-card-plus-r:before {
  content: "\f162";
}

.bcp-cards-b:before {
  content: "\f163";
}

.bcp-cards-l:before {
  content: "\f164";
}

.bcp-cards-r:before {
  content: "\f165";
}

.bcp-chart-bar-b:before {
  content: "\f166";
}

.bcp-chart-bar-l:before {
  content: "\f167";
}

.bcp-chart-bar-r:before {
  content: "\f168";
}

.bcp-chart-pie-b:before {
  content: "\f169";
}

.bcp-chart-pie-l:before {
  content: "\f16a";
}

.bcp-chart-pie-r:before {
  content: "\f16b";
}

.bcp-check-b:before {
  content: "\f16c";
}

.bcp-check-circle-b:before {
  content: "\f16d";
}

.bcp-check-circle-l:before {
  content: "\f16e";
}

.bcp-check-circle-r:before {
  content: "\f16f";
}

.bcp-check-r:before {
  content: "\f170";
}

.bcp-chicken-leg-b:before {
  content: "\f171";
}

.bcp-chicken-leg-l:before {
  content: "\f172";
}

.bcp-chicken-leg-r:before {
  content: "\f173";
}

.bcp-chronometer-b:before {
  content: "\f174";
}

.bcp-chronometer-l:before {
  content: "\f175";
}

.bcp-chronometer-r:before {
  content: "\f176";
}

.bcp-circle-connection-b:before {
  content: "\f177";
}

.bcp-circle-connection-l:before {
  content: "\f178";
}

.bcp-circle-connection-r:before {
  content: "\f179";
}

.bcp-clock-b:before {
  content: "\f17a";
}

.bcp-clock-l:before {
  content: "\f17b";
}

.bcp-clock-r:before {
  content: "\f17c";
}

.bcp-close-b:before {
  content: "\f17d";
}

.bcp-close-circle-b:before {
  content: "\f17e";
}

.bcp-close-circle-l:before {
  content: "\f17f";
}

.bcp-close-circle-r:before {
  content: "\f180";
}

.bcp-close-r:before {
  content: "\f181";
}

.bcp-compass-b:before {
  content: "\f182";
}

.bcp-compass-l:before {
  content: "\f183";
}

.bcp-compass-r:before {
  content: "\f184";
}

.bcp-desktop-b:before {
  content: "\f185";
}

.bcp-desktop-l:before {
  content: "\f186";
}

.bcp-desktop-r:before {
  content: "\f187";
}

.bcp-diamond-b:before {
  content: "\f188";
}

.bcp-diamond-l:before {
  content: "\f189";
}

.bcp-diamond-r:before {
  content: "\f18a";
}

.bcp-digital-token-b:before {
  content: "\f18b";
}

.bcp-digital-token-l:before {
  content: "\f18c";
}

.bcp-digital-token-r:before {
  content: "\f18d";
}

.bcp-document-approved-b:before {
  content: "\f18e";
}

.bcp-document-approved-l:before {
  content: "\f18f";
}

.bcp-document-approved-r:before {
  content: "\f190";
}

.bcp-document-b:before {
  content: "\f191";
}

.bcp-document-card-b:before {
  content: "\f192";
}

.bcp-document-card-l:before {
  content: "\f193";
}

.bcp-document-card-r:before {
  content: "\f194";
}

.bcp-document-denied-b:before {
  content: "\f195";
}

.bcp-document-denied-l:before {
  content: "\f196";
}

.bcp-document-denied-r:before {
  content: "\f197";
}

.bcp-document-l:before {
  content: "\f198";
}

.bcp-document-less-b:before {
  content: "\f199";
}

.bcp-document-less-l:before {
  content: "\f19a";
}

.bcp-document-less-r:before {
  content: "\f19b";
}

.bcp-document-pencil-b:before {
  content: "\f19c";
}

.bcp-document-pencil-l:before {
  content: "\f19d";
}

.bcp-document-pencil-r:before {
  content: "\f19e";
}

.bcp-document-plus-b:before {
  content: "\f19f";
}

.bcp-document-plus-l:before {
  content: "\f1a0";
}

.bcp-document-plus-r:before {
  content: "\f1a1";
}

.bcp-document-r:before {
  content: "\f1a2";
}

.bcp-documents-b:before {
  content: "\f1a3";
}

.bcp-documents-l:before {
  content: "\f1a4";
}

.bcp-documents-r:before {
  content: "\f1a5";
}

.bcp-edit-b:before {
  content: "\f1a6";
}

.bcp-edit-l:before {
  content: "\f1a7";
}

.bcp-edit-r:before {
  content: "\f1a8";
}

.bcp-excel-b:before {
  content: "\f1a9";
}

.bcp-excel-l:before {
  content: "\f1aa";
}

.bcp-excel-r:before {
  content: "\f1ab";
}

.bcp-exchange-r:before {
  content: "\f1ac";
}

.bcp-exclamation-triangle-b:before {
  content: "\f1ad";
}

.bcp-exclamation-triangle-l:before {
  content: "\f1ae";
}

.bcp-exclamation-triangle-r:before {
  content: "\f1af";
}

.bcp-external-link-r:before {
  content: "\f1b0";
}

.bcp-eye-b:before {
  content: "\f1b1";
}

.bcp-eye-l:before {
  content: "\f1b2";
}

.bcp-eye-r:before {
  content: "\f1b3";
}

.bcp-eye-slash-b:before {
  content: "\f1b4";
}

.bcp-eye-slash-l:before {
  content: "\f1b5";
}

.bcp-eye-slash-r:before {
  content: "\f1b6";
}

.bcp-face-happy-b:before {
  content: "\f1b7";
}

.bcp-face-happy-l:before {
  content: "\f1b8";
}

.bcp-face-happy-r:before {
  content: "\f1b9";
}

.bcp-face-sad-b:before {
  content: "\f1ba";
}

.bcp-face-sad-l:before {
  content: "\f1bb";
}

.bcp-face-sad-r:before {
  content: "\f1bc";
}

.bcp-face-satisfied-b:before {
  content: "\f1bd";
}

.bcp-face-satisfied-l:before {
  content: "\f1be";
}

.bcp-face-satisfied-r:before {
  content: "\f1bf";
}

.bcp-face-serious-b:before {
  content: "\f1c0";
}

.bcp-face-serious-l:before {
  content: "\f1c1";
}

.bcp-face-serious-r:before {
  content: "\f1c2";
}

.bcp-faceid-b:before {
  content: "\f1c3";
}

.bcp-faceid-l:before {
  content: "\f1c4";
}

.bcp-faceid-r:before {
  content: "\f1c5";
}

.bcp-factory-b:before {
  content: "\f1c6";
}

.bcp-factory-l:before {
  content: "\f1c7";
}

.bcp-factory-r:before {
  content: "\f1c8";
}

.bcp-faucet-b:before {
  content: "\f1c9";
}

.bcp-faucet-l:before {
  content: "\f1ca";
}

.bcp-faucet-r:before {
  content: "\f1cb";
}

.bcp-filter-b:before {
  content: "\f1cc";
}

.bcp-filter-l:before {
  content: "\f1cd";
}

.bcp-filter-r:before {
  content: "\f1ce";
}

.bcp-fingerprint-b:before {
  content: "\f1cf";
}

.bcp-fingerprint-l:before {
  content: "\f1d0";
}

.bcp-fingerprint-r:before {
  content: "\f1d1";
}

.bcp-flag-b:before {
  content: "\f1d2";
}

.bcp-flag-l:before {
  content: "\f1d3";
}

.bcp-flag-r:before {
  content: "\f1d4";
}

.bcp-folder-b:before {
  content: "\f1d5";
}

.bcp-folder-l:before {
  content: "\f1d6";
}

.bcp-folder-r:before {
  content: "\f1d7";
}

.bcp-fountain-pen-b:before {
  content: "\f1d8";
}

.bcp-fountain-pen-l:before {
  content: "\f1d9";
}

.bcp-fountain-pen-r:before {
  content: "\f1da";
}

.bcp-funnel-b:before {
  content: "\f1db";
}

.bcp-funnel-l:before {
  content: "\f1dc";
}

.bcp-funnel-r:before {
  content: "\f1dd";
}

.bcp-gas-pump-b:before {
  content: "\f1de";
}

.bcp-gas-pump-l:before {
  content: "\f1df";
}

.bcp-gas-pump-r:before {
  content: "\f1e0";
}

.bcp-gavel-b:before {
  content: "\f1e1";
}

.bcp-gavel-l:before {
  content: "\f1e2";
}

.bcp-gavel-r:before {
  content: "\f1e3";
}

.bcp-gear-b:before {
  content: "\f1e4";
}

.bcp-gear-l:before {
  content: "\f1e5";
}

.bcp-gear-r:before {
  content: "\f1e6";
}

.bcp-genre-l:before {
  content: "\f1e7";
}

.bcp-genre-r:before {
  content: "\f1e8";
}

.bcp-grid-b:before {
  content: "\f1e9";
}

.bcp-grid-l:before {
  content: "\f1ea";
}

.bcp-grid-r:before {
  content: "\f1eb";
}

.bcp-hand-holding-b:before {
  content: "\f1ec";
}

.bcp-hand-holding-l:before {
  content: "\f1ed";
}

.bcp-hand-holding-r:before {
  content: "\f1ee";
}

.bcp-hand-like-b:before {
  content: "\f1ef";
}

.bcp-hand-like-l:before {
  content: "\f1f0";
}

.bcp-hand-like-r:before {
  content: "\f1f1";
}

.bcp-headphone-b:before {
  content: "\f1f2";
}

.bcp-headphone-l:before {
  content: "\f1f3";
}

.bcp-headphone-r:before {
  content: "\f1f4";
}

.bcp-heart-b:before {
  content: "\f1f5";
}

.bcp-heart-l:before {
  content: "\f1f6";
}

.bcp-heart-r:before {
  content: "\f1f7";
}

.bcp-heart-slash-b:before {
  content: "\f1f8";
}

.bcp-heart-slash-l:before {
  content: "\f1f9";
}

.bcp-heart-slash-r:before {
  content: "\f1fa";
}

.bcp-hourglass-b:before {
  content: "\f1fb";
}

.bcp-hourglass-l:before {
  content: "\f1fc";
}

.bcp-hourglass-r:before {
  content: "\f1fd";
}

.bcp-house-b:before {
  content: "\f1fe";
}

.bcp-house-l:before {
  content: "\f1ff";
}

.bcp-house-r:before {
  content: "\f200";
}

.bcp-idcard-b:before {
  content: "\f201";
}

.bcp-idcard-l:before {
  content: "\f202";
}

.bcp-idcard-r:before {
  content: "\f203";
}

.bcp-image-b:before {
  content: "\f204";
}

.bcp-image-l:before {
  content: "\f205";
}

.bcp-image-r:before {
  content: "\f206";
}

.bcp-info-b:before {
  content: "\f207";
}

.bcp-info-l:before {
  content: "\f208";
}

.bcp-info-r:before {
  content: "\f209";
}

.bcp-internet-connection-b:before {
  content: "\f20a";
}

.bcp-internet-connection-slash-b:before {
  content: "\f20b";
}

.bcp-key-b:before {
  content: "\f20c";
}

.bcp-key-l:before {
  content: "\f20d";
}

.bcp-key-r:before {
  content: "\f20e";
}

.bcp-label-b:before {
  content: "\f20f";
}

.bcp-label-l:before {
  content: "\f210";
}

.bcp-label-r:before {
  content: "\f211";
}

.bcp-laptop-l:before {
  content: "\f212";
}

.bcp-laptop-r:before {
  content: "\f213";
}

.bcp-light-b:before {
  content: "\f214";
}

.bcp-light-l:before {
  content: "\f215";
}

.bcp-light-r:before {
  content: "\f216";
}

.bcp-list-l:before {
  content: "\f217";
}

.bcp-list-r:before {
  content: "\f218";
}

.bcp-lock-b:before {
  content: "\f219";
}

.bcp-lock-l:before {
  content: "\f21a";
}

.bcp-lock-open-b:before {
  content: "\f21b";
}

.bcp-lock-open-l:before {
  content: "\f21c";
}

.bcp-lock-open-password-b:before {
  content: "\f21d";
}

.bcp-lock-open-password-l:before {
  content: "\f21e";
}

.bcp-lock-open-password-r:before {
  content: "\f21f";
}

.bcp-lock-open-r:before {
  content: "\f220";
}

.bcp-lock-password-b:before {
  content: "\f221";
}

.bcp-lock-password-l:before {
  content: "\f222";
}

.bcp-lock-password-r:before {
  content: "\f223";
}

.bcp-lock-r:before {
  content: "\f224";
}

.bcp-mail-b:before {
  content: "\f225";
}

.bcp-mail-l:before {
  content: "\f226";
}

.bcp-mail-r:before {
  content: "\f227";
}

.bcp-man-another-b:before {
  content: "\f228";
}

.bcp-man-another-l:before {
  content: "\f229";
}

.bcp-man-b:before {
  content: "\f22a";
}

.bcp-man-circle-b:before {
  content: "\f22b";
}

.bcp-man-circle-l:before {
  content: "\f22c";
}

.bcp-man-circle-r:before {
  content: "\f22d";
}

.bcp-man-group-b:before {
  content: "\f22e";
}

.bcp-man-group-l:before {
  content: "\f22f";
}

.bcp-man-group-r:before {
  content: "\f230";
}

.bcp-man-l:before {
  content: "\f231";
}

.bcp-man-plus-b:before {
  content: "\f232";
}

.bcp-man-plus-l:before {
  content: "\f233";
}

.bcp-man-plus-r:before {
  content: "\f234";
}

.bcp-man-r:before {
  content: "\f235";
}

.bcp-map-marker-b:before {
  content: "\f236";
}

.bcp-map-marker-l:before {
  content: "\f237";
}

.bcp-map-marker-r:before {
  content: "\f238";
}

.bcp-medal-b:before {
  content: "\f239";
}

.bcp-medal-l:before {
  content: "\f23a";
}

.bcp-medal-r:before {
  content: "\f23b";
}

.bcp-medicine-cabinet-b:before {
  content: "\f23c";
}

.bcp-medicine-cabinet-l:before {
  content: "\f23d";
}

.bcp-medicine-cabinet-r:before {
  content: "\f23e";
}

.bcp-megaphone-b:before {
  content: "\f23f";
}

.bcp-megaphone-l:before {
  content: "\f240";
}

.bcp-megaphone-r:before {
  content: "\f241";
}

.bcp-menu-r:before {
  content: "\f242";
}

.bcp-message-b:before {
  content: "\f243";
}

.bcp-message-l:before {
  content: "\f244";
}

.bcp-message-r:before {
  content: "\f245";
}

.bcp-metric-down-r:before {
  content: "\f246";
}

.bcp-metric-up-r:before {
  content: "\f247";
}

.bcp-microphone-b:before {
  content: "\f248";
}

.bcp-microphone-l:before {
  content: "\f249";
}

.bcp-microphone-r:before {
  content: "\f24a";
}

.bcp-minus-circle-b:before {
  content: "\f24b";
}

.bcp-minus-circle-l:before {
  content: "\f24c";
}

.bcp-minus-circle-r:before {
  content: "\f24d";
}

.bcp-minus-r:before {
  content: "\f24e";
}

.bcp-money-bag-dollar-b:before {
  content: "\f24f";
}

.bcp-money-bag-dollar-l:before {
  content: "\f250";
}

.bcp-money-bag-dollar-r:before {
  content: "\f251";
}

.bcp-money-bag-euro-b:before {
  content: "\f252";
}

.bcp-money-bag-euro-l:before {
  content: "\f253";
}

.bcp-money-bag-euro-r:before {
  content: "\f254";
}

.bcp-money-bag-soles-b:before {
  content: "\f255";
}

.bcp-money-bag-soles-l:before {
  content: "\f256";
}

.bcp-money-bag-soles-r:before {
  content: "\f257";
}

.bcp-money-dollar-r:before {
  content: "\f258";
}

.bcp-money-dollars-circle-b:before {
  content: "\f259";
}

.bcp-money-dollars-exchange-l:before {
  content: "\f25a";
}

.bcp-money-dollars-exchange-r:before {
  content: "\f25b";
}

.bcp-money-down-b:before {
  content: "\f25c";
}

.bcp-money-down-l:before {
  content: "\f25d";
}

.bcp-money-down-r:before {
  content: "\f25e";
}

.bcp-money-euro-circle-b:before {
  content: "\f25f";
}

.bcp-money-euro-exchange-l:before {
  content: "\f260";
}

.bcp-money-euro-exchange-r:before {
  content: "\f261";
}

.bcp-money-euro-r:before {
  content: "\f262";
}

.bcp-money-plus-b:before {
  content: "\f263";
}

.bcp-money-plus-l:before {
  content: "\f264";
}

.bcp-money-plus-r:before {
  content: "\f265";
}

.bcp-money-soles-circle-b:before {
  content: "\f266";
}

.bcp-money-soles-exchange-l:before {
  content: "\f267";
}

.bcp-money-soles-exchange-r:before {
  content: "\f268";
}

.bcp-money-soles-r:before {
  content: "\f269";
}

.bcp-money-up-b:before {
  content: "\f26a";
}

.bcp-money-up-l:before {
  content: "\f26b";
}

.bcp-money-up-r:before {
  content: "\f26c";
}

.bcp-paper-plane-b:before {
  content: "\f26d";
}

.bcp-paper-plane-l:before {
  content: "\f26e";
}

.bcp-paper-plane-r:before {
  content: "\f26f";
}

.bcp-paperclip-r:before {
  content: "\f270";
}

.bcp-pen-b:before {
  content: "\f271";
}

.bcp-pen-l:before {
  content: "\f272";
}

.bcp-pen-r:before {
  content: "\f273";
}

.bcp-percentage-down-b:before {
  content: "\f274";
}

.bcp-percentage-down-l:before {
  content: "\f275";
}

.bcp-percentage-down-r:before {
  content: "\f276";
}

.bcp-percentage-square-b:before {
  content: "\f277";
}

.bcp-percentage-square-l:before {
  content: "\f278";
}

.bcp-percentage-square-r:before {
  content: "\f279";
}

.bcp-percentage-up-b:before {
  content: "\f27a";
}

.bcp-percentage-up-l:before {
  content: "\f27b";
}

.bcp-percentage-up-r:before {
  content: "\f27c";
}

.bcp-persons-b:before {
  content: "\f27d";
}

.bcp-persons-l:before {
  content: "\f27e";
}

.bcp-persons-r:before {
  content: "\f27f";
}

.bcp-piggy-bank-b:before {
  content: "\f280";
}

.bcp-piggy-bank-l:before {
  content: "\f281";
}

.bcp-piggy-bank-r:before {
  content: "\f282";
}

.bcp-play-circle-b:before {
  content: "\f283";
}

.bcp-play-circle-l:before {
  content: "\f284";
}

.bcp-play-circle-r:before {
  content: "\f285";
}

.bcp-plus-circle-b:before {
  content: "\f286";
}

.bcp-plus-circle-l:before {
  content: "\f287";
}

.bcp-plus-circle-r:before {
  content: "\f288";
}

.bcp-plus-r:before {
  content: "\f289";
}

.bcp-points-horiz-r:before {
  content: "\f28a";
}

.bcp-points-vert-r:before {
  content: "\f28b";
}

.bcp-pos-b:before {
  content: "\f28c";
}

.bcp-pos-l:before {
  content: "\f28d";
}

.bcp-pos-r:before {
  content: "\f28e";
}

.bcp-print-b:before {
  content: "\f28f";
}

.bcp-print-l:before {
  content: "\f290";
}

.bcp-print-r:before {
  content: "\f291";
}

.bcp-qrcode-b:before {
  content: "\f292";
}

.bcp-qrcode-l:before {
  content: "\f293";
}

.bcp-qrcode-r:before {
  content: "\f294";
}

.bcp-question-circle-b:before {
  content: "\f295";
}

.bcp-question-circle-l:before {
  content: "\f296";
}

.bcp-question-circle-r:before {
  content: "\f297";
}

.bcp-redirection-b:before {
  content: "\f298";
}

.bcp-redirection-l:before {
  content: "\f299";
}

.bcp-redirection-r:before {
  content: "\f29a";
}

.bcp-robot-b:before {
  content: "\f29b";
}

.bcp-robot-l:before {
  content: "\f29c";
}

.bcp-robot-r:before {
  content: "\f29d";
}

.bcp-rotate-left-r:before {
  content: "\f29e";
}

.bcp-rotate-right-r:before {
  content: "\f29f";
}

.bcp-safe-box-b:before {
  content: "\f2a0";
}

.bcp-safe-box-l:before {
  content: "\f2a1";
}

.bcp-safe-box-r:before {
  content: "\f2a2";
}

.bcp-search-b:before {
  content: "\f2a3";
}

.bcp-search-r:before {
  content: "\f2a4";
}

.bcp-share-b:before {
  content: "\f2a5";
}

.bcp-share-ios-r:before {
  content: "\f2a6";
}

.bcp-share-l:before {
  content: "\f2a7";
}

.bcp-share-r:before {
  content: "\f2a8";
}

.bcp-shield-b:before {
  content: "\f2a9";
}

.bcp-shield-insurance-b:before {
  content: "\f2aa";
}

.bcp-shield-insurance-l:before {
  content: "\f2ab";
}

.bcp-shield-insurance-r:before {
  content: "\f2ac";
}

.bcp-shield-l:before {
  content: "\f2ad";
}

.bcp-shield-r:before {
  content: "\f2ae";
}

.bcp-shopping-bag-b:before {
  content: "\f2af";
}

.bcp-shopping-bag-l:before {
  content: "\f2b0";
}

.bcp-shopping-bag-r:before {
  content: "\f2b1";
}

.bcp-shopping-car-b:before {
  content: "\f2b2";
}

.bcp-shopping-car-l:before {
  content: "\f2b3";
}

.bcp-shopping-car-r:before {
  content: "\f2b4";
}

.bcp-sign-in-r:before {
  content: "\f2b5";
}

.bcp-sign-out-r:before {
  content: "\f2b6";
}

.bcp-smartphone-b:before {
  content: "\f2b7";
}

.bcp-smartphone-call-b:before {
  content: "\f2b8";
}

.bcp-smartphone-call-l:before {
  content: "\f2b9";
}

.bcp-smartphone-call-r:before {
  content: "\f2ba";
}

.bcp-smartphone-l:before {
  content: "\f2bb";
}

.bcp-smartphone-r:before {
  content: "\f2bc";
}

.bcp-social-facebook-b:before {
  content: "\f2bd";
}

.bcp-social-facebook-l:before {
  content: "\f2be";
}

.bcp-social-facebook-r:before {
  content: "\f2bf";
}

.bcp-social-instagram-b:before {
  content: "\f2c0";
}

.bcp-social-instagram-l:before {
  content: "\f2c1";
}

.bcp-social-instagram-r:before {
  content: "\f2c2";
}

.bcp-social-linkedin-b:before {
  content: "\f2c3";
}

.bcp-social-linkedin-l:before {
  content: "\f2c4";
}

.bcp-social-linkedin-r:before {
  content: "\f2c5";
}

.bcp-social-twitter-b:before {
  content: "\f2c6";
}

.bcp-social-twitter-l:before {
  content: "\f2c7";
}

.bcp-social-twitter-r:before {
  content: "\f2c8";
}

.bcp-social-youtube-b:before {
  content: "\f2c9";
}

.bcp-social-youtube-l:before {
  content: "\f2ca";
}

.bcp-social-youtube-r:before {
  content: "\f2cb";
}

.bcp-soup-b:before {
  content: "\f2cc";
}

.bcp-soup-l:before {
  content: "\f2cd";
}

.bcp-soup-r:before {
  content: "\f2ce";
}

.bcp-star-b:before {
  content: "\f2cf";
}

.bcp-star-l:before {
  content: "\f2d0";
}

.bcp-star-r:before {
  content: "\f2d1";
}

.bcp-store-b:before {
  content: "\f2d2";
}

.bcp-store-l:before {
  content: "\f2d3";
}

.bcp-store-r:before {
  content: "\f2d4";
}

.bcp-table-b:before {
  content: "\f2d5";
}

.bcp-table-l:before {
  content: "\f2d6";
}

.bcp-table-r:before {
  content: "\f2d7";
}

.bcp-tablet-b:before {
  content: "\f2d8";
}

.bcp-tablet-l:before {
  content: "\f2d9";
}

.bcp-tablet-r:before {
  content: "\f2da";
}

.bcp-tag-b:before {
  content: "\f2db";
}

.bcp-tag-l:before {
  content: "\f2dc";
}

.bcp-tag-r:before {
  content: "\f2dd";
}

.bcp-target-b:before {
  content: "\f2de";
}

.bcp-target-l:before {
  content: "\f2df";
}

.bcp-target-r:before {
  content: "\f2e0";
}

.bcp-telephone-b:before {
  content: "\f2e1";
}

.bcp-telephone-l:before {
  content: "\f2e2";
}

.bcp-telephone-r:before {
  content: "\f2e3";
}

.bcp-ticket-b:before {
  content: "\f2e4";
}

.bcp-ticket-l:before {
  content: "\f2e5";
}

.bcp-ticket-r:before {
  content: "\f2e6";
}

.bcp-token-b:before {
  content: "\f2e7";
}

.bcp-token-l:before {
  content: "\f2e8";
}

.bcp-token-r:before {
  content: "\f2e9";
}

.bcp-tool-b:before {
  content: "\f2ea";
}

.bcp-tool-l:before {
  content: "\f2eb";
}

.bcp-tool-r:before {
  content: "\f2ec";
}

.bcp-transaction-r:before {
  content: "\f2ed";
}

.bcp-trash-b:before {
  content: "\f2ee";
}

.bcp-trash-l:before {
  content: "\f2ef";
}

.bcp-trash-r:before {
  content: "\f2f0";
}

.bcp-trophy-b:before {
  content: "\f2f1";
}

.bcp-trophy-l:before {
  content: "\f2f2";
}

.bcp-trophy-r:before {
  content: "\f2f3";
}

.bcp-turn-r:before {
  content: "\f2f4";
}

.bcp-umbrella-b:before {
  content: "\f2f5";
}

.bcp-umbrella-l:before {
  content: "\f2f6";
}

.bcp-umbrella-r:before {
  content: "\f2f7";
}

.bcp-wallet-b:before {
  content: "\f2f8";
}

.bcp-wallet-l:before {
  content: "\f2f9";
}

.bcp-wallet-r:before {
  content: "\f2fa";
}

.bcp-water-drop-b:before {
  content: "\f2fb";
}

.bcp-water-drop-l:before {
  content: "\f2fc";
}

.bcp-water-drop-r:before {
  content: "\f2fd";
}

.bcp-website-approved-b:before {
  content: "\f2fe";
}

.bcp-website-approved-l:before {
  content: "\f2ff";
}

.bcp-website-approved-r:before {
  content: "\f300";
}

.bcp-website-b:before {
  content: "\f301";
}

.bcp-website-card-b:before {
  content: "\f302";
}

.bcp-website-card-l:before {
  content: "\f303";
}

.bcp-website-card-r:before {
  content: "\f304";
}

.bcp-website-denied-b:before {
  content: "\f305";
}

.bcp-website-denied-l:before {
  content: "\f306";
}

.bcp-website-denied-r:before {
  content: "\f307";
}

.bcp-website-l:before {
  content: "\f308";
}

.bcp-website-less-b:before {
  content: "\f309";
}

.bcp-website-less-l:before {
  content: "\f30a";
}

.bcp-website-less-r:before {
  content: "\f30b";
}

.bcp-website-plus-b:before {
  content: "\f30c";
}

.bcp-website-plus-l:before {
  content: "\f30d";
}

.bcp-website-plus-r:before {
  content: "\f30e";
}

.bcp-website-r:before {
  content: "\f30f";
}

.bcp-woman-b:before {
  content: "\f310";
}

.bcp-woman-group-b:before {
  content: "\f311";
}

.bcp-woman-group-l:before {
  content: "\f312";
}

.bcp-woman-group-r:before {
  content: "\f313";
}

.bcp-woman-l:before {
  content: "\f314";
}

.bcp-woman-r:before {
  content: "\f315";
}

.bcp-world-b:before {
  content: "\f316";
}

.bcp-world-l:before {
  content: "\f317";
}

.bcp-world-r:before {
  content: "\f318";
}

.bcp-yape-link-b:before {
  content: "\f319";
}

.bcp-yape-link-l:before {
  content: "\f31a";
}

.bcp-yape-link-r:before {
  content: "\f31b";
}

.bcp-zoom-in-b:before {
  content: "\f31c";
}

.bcp-zoom-in-r:before {
  content: "\f31d";
}

.bcp-zoom-out-b:before {
  content: "\f31e";
}

.bcp-zoom-out-r:before {
  content: "\f31f";
}
