@import "../sass/variables";
.cbaf-form {
  //   & .row {
  //     // margin-left: inherit;
  //     // margin-right: inherit;
  //   }
  &__form {
    &-group {
      margin: 25px 0;
      & label {
        color: $bcp-blue;
        display: block;
        font-size: 14px;
        font-weight: 600;
        margin: 0 0 8px 0;
        text-align: left;
        &.danger {
          color: $bcp-red;
        }
      }
      &.hidden,
      & > .hidden {
        display: none;
      }
      &-subtitle {
        margin: 16px 0 18px 0;
        font-size: 18px;
        text-transform: uppercase;
        color: $bcp-orange-500;
        & > p {
          text-align: left;
          margin: 0 10px 0 0;
          font-weight: 800;
          &,
          & + span {
            display: inline-block;
          }
        }
      }
      &-icon {
        width: 40px;
        cursor: pointer;
        height: auto;
      }
    }

    &-control {
      border: none;
      border-bottom: 1px solid $bcp-blue;
      min-height: 28px;
      outline: none;
      width: 100%;
      &.ng-touched.ng-invalid {
        border-color: $bcp-red;
      }
      &.btn {
        border: none;
      }
      &:disabled {
        border-bottom: 1px solid #888888;
      }
      &-file {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        & + label {
          margin: 0;
          padding: 3px 18px;
          @extend .bcp-btn-info !optional;
        }
        &.orange + label {
          @extend .bcp-btn-orange !optional;
        }
      }
      &-file-2 {
        height: 0.1px;
        width: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        & + label {
          @extend .cbaf-form__form-control;
          color: #888888;
          font-style: italic;
          font-weight: 300;
          margin: 0;
        }
      }
      &-file-orange {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        & + label {
          margin: 0;
          padding: 3px 18px;
          @extend .bcp-btn-orange !optional;
        }
      }
      &-sub {
        display: block;
        color: #888888;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: normal;
      }
      &-error,
      &-error > label {
        color: $bcp-red !important;
        font-size: 12px !important;
        margin: 0;
      }
      &.error {
        border-color: $bcp-red;
      }
    }
  }
}
.cbaf-form-control {
  width: 100%;
  border: none;
  border-bottom: 1px solid #012d74;
  outline: none;
  min-height: 28px;
  &.gray:focus {
    background: $background-50;
  }
  &-error {
    color: red !important;
    margin: 0;
  }
  &.error {
    border-color: #ff0000;
  }
}
.cbaf-form-group {
  display: inline-block;
  &:not(:last-of-type) {
    margin-right: 15px;
  }
  & > label {
    color: $bcp-blue;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px 0;
  }
  &-icon {
    width: 40px;
    height: auto;
  }
}
input[type="text"]:disabled {
  background: #fff;
}

input[type="text"] {
  transition: all 0.3s ease-in-out;
}
input[type="text"]:focus {
  border-bottom: 2px solid #012d74;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  //   background-image: url("assets/icons/calendar.svg");
  background-position: right center;
  height: 20px;
  width: 20px;
  color: transparent;
  border-width: thin;
}

.cbaf-optional-field {
  font-size: 13px;
  font-weight: lighter;
  color: $bcp-orange-500;
}
