//colors
$bcp-blue: #012d74;
$bcp-black: #282828;
$bcp-gray: #7194bd;
$bcp-grey: #eff3f9;
$bcp-grey-2: #f0f5d9;
$bcp-green: #93c63f;
$bcp-sky-blue: #2cc0d5;
$bcp-orange-700: #cc6000;
$bcp-orange-500: #ff7800;
$bcp-orange-400: #ff8e29;
$bcp-dark-gray: #454546;
$bcp-light-gray: #eff3f9;
$bcp-light-grey: #d6dce9;
$bcp-light-grey-2: #a2b5d2;
$bcp-red: #ff003b;
$bcp-red-2: #fdebef;
//backgrounds
$background-900: #264b78;
$background-700: #527094;
$background-500: #7f95b1;
$background-400: #95a7bf;
$background-300: #acbace;
$background-200: #c2ccdc;
$background-100: #d8dfea;
$background-50:	 #eff2f9;

$onsurface-050: #f8f9fb;