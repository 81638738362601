@import "./variables";
@import "./form";
@import "./table";
@import "./icons";

.p-16,
.cbaf-modal-backdrop{
  z-index: 1050 !important;
}
.btn-back-container {
  padding-left: calc(100vw * 0.1);
  padding-right: calc(100vw * 0.1);
  transition: all 0.3s ease-in-out;
  width: 100%;
  padding-top: 22px;
  padding-bottom: 22px;
}
.background-white {
  background: #fff;
}
.hidden {
  visibility: hidden;
}
.justify-center {
  justify-content: center;
}
.pv-15 {
  padding: 16px 0;
}
.m-0 {
  margin: 0 !important;
}
.relative {
  position: relative;
}
.btn-top-left-container {
  position: absolute;
  z-index: 9;
  top: 24px;
  left: calc(100vw * 0.1);
  transition: all 0.3s ease-in-out;
  &.l-0 {
    left: 0;
  }
  &.top-100 {
    top: 100px;
  }
  @media screen and (max-width: 1200px) {
    left: 16px;
  }
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.w-100 {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .p-16,
  .btn-back-container {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
}
